/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CForm,
  CInputFile,
  CTextarea,
  CInputCheckbox,
  CSelect,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { resetData } from 'src/slices/NewsItemSlice';
import { detailNews, upsertNews } from 'src/slices/NewsSlice';
import SunEditorCustom from 'src/components/SunEditor';
import DatePicker from 'src/components/DatePicker';
import apiClient from 'src/api/api-client';
import { uploadFileURI } from 'src/api';
import dayjs from 'dayjs';
import NewType from 'src/enums/NewType';

const NewsDetail = () => {
  const routeParams = useParams();
  const { id } = routeParams;
  const [openModalProject, setOpenModalProject] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const newsItem = useSelector(state => state.newsItem);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const formik = useFormik({
    initialValues: {
      title_vi: newsItem.title_vi || '',
      title_en: newsItem.title_en || '',
      title_jp: newsItem.title_jp || '',
      date: new Date(newsItem.date || null),
      author: newsItem.author || '',
      description_vi: newsItem.description_vi || '',
      description_en: newsItem.description_en || '',
      description_jp: newsItem.description_jp || '',
      content_vi: newsItem.content_vi || '',
      content_en: newsItem.content_en || '',
      content_jp: newsItem.content_jp || '',
      status: newsItem.status || 'inactive',
      main_post: newsItem.main_post || 0,
      popular_new: newsItem.popular_new || 0,
      topic: newsItem.topic || '',
      new_type: newsItem.new_type || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title_vi: Yup.string().required('Vui lòng điền tiêu đề tiếng Việt'),
      date: Yup.date()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .required('Vui lòng điền ngày tạo'),
      author: Yup.string().required('Vui lòng điền tác giả'),
      description_vi: Yup.string().required('Vui lòng điền mô tả tiếng Việt'),
      content_vi: Yup.string().required('Vui lòng điền nội dung tiếng Việt'),
      topic: Yup.string().required('Vui lòng điền chủ đề'),
      new_type: Yup.string().required('Vui lòng chọn kiểu bài'),
    }),
    onSubmit: async vals => {
      let image = newsItem.banner_url;
      if (selectedImage) {
        const uploadFormData = new FormData();
        uploadFormData.append('file', selectedImage);
        const uploadRes = await apiClient.handleRequest({
          method: 'post',
          url: `${uploadFileURI}`,
          data: uploadFormData,
        });
        image = uploadRes.data.data;
      }

      const res = await dispatch(
        upsertNews({
          id: id === 'create' ? '' : id,
          data: { ...vals, banner_url: image, date: dayjs(vals.date).format('YYYY-MM-DD') },
        }),
      );
      if (res.payload === true) {
        history.push(`/news`);
      }
    },
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
    initialValues,
  } = formik;
  const imageChange = e => {
    setSelectedImage(e.target.files[0]);
  };

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    setSelectedImage();
    history.push(`/news`);
  };

  useEffect(() => {
    const load = async () => {
      if (id !== 'create') {
        await dispatch(detailNews(id));
      } else {
        dispatch(resetData());
        setSelectedImage();
      }
    };

    load();
  }, [id]);

  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
        validated={validated}
      >
        <CCard>
          <CCardHeader>Tạo mới tin tức</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="avatar">Tải ảnh</CLabel>
                  <CFormGroup variant="custom-checkbox">
                    <CInputFile
                      custom
                      id="image"
                      onChange={e => {
                        imageChange(e);
                      }}
                      accept="image/*"
                    />
                    <CLabel htmlFor="image" variant="custom-file">
                      {selectedImage?.name || 'Choose file'}
                    </CLabel>
                  </CFormGroup>
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="title_vi">Tiêu đề tiếng Việt</CLabel>
                  <CInput
                    id="title_vi"
                    name="title_vi"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.title_vi || ''}
                  />
                  {touched.title_vi && errors.title_vi ? (
                    <small className="text-danger">{errors.title_vi}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="title_en">Tiêu đề tiếng Anh</CLabel>
                  <CInput
                    id="title_en"
                    name="title_en"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.title_en || ''}
                  />
                  {touched.title_en && errors.title_en ? (
                    <small className="text-danger">{errors.title_en}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="title_vi">Tiêu đề tiếng Nhật</CLabel>
                  <CInput
                    id="title_jp"
                    name="title_jp"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.title_jp || ''}
                  />
                  {touched.title_jp && errors.title_jp ? (
                    <small className="text-danger">{errors.title_jp}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                {selectedImage && (
                  <div>
                    <img
                      style={{ width: '-webkit-fill-available' }}
                      src={URL.createObjectURL(selectedImage)}
                      alt=""
                    />
                  </div>
                )}
                {!selectedImage && newsItem.banner_url && (
                  <div>
                    <img
                      style={{ width: '-webkit-fill-available' }}
                      src={newsItem.banner_url}
                      alt=""
                    />
                  </div>
                )}
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="author">Tác giả</CLabel>
                  <CInput
                    id="author"
                    name="author"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.author || ''}
                  />
                  {touched.author && errors.author ? (
                    <small className="text-danger">{errors.author}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="topic">Chủ đề</CLabel>
                  <CInput
                    id="topic"
                    name="topic"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.topic || ''}
                  />
                  {touched.topic && errors.topic ? (
                    <small className="text-danger">{errors.topic}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="description_vi">Mô tả ngắn tiếng Việt</CLabel>
                  <CTextarea
                    id="description_vi"
                    name="description_vi"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.description_vi || ''}
                  />
                  {touched.description_vi && errors.description_vi ? (
                    <small className="text-danger">{errors.description_vi}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="description_en">Mô tả ngắn tiếng Anh</CLabel>
                  <CTextarea
                    id="description_en"
                    name="description_en"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.description_en || ''}
                  />
                  {touched.description_en && errors.description_en ? (
                    <small className="text-danger">{errors.description_en}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="description_jp">Mô tả ngắn tiếng Nhật</CLabel>
                  <CTextarea
                    id="description_jp"
                    name="description_jp"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.description_jp || ''}
                  />
                  {touched.description_jp && errors.description_jp ? (
                    <small className="text-danger">{errors.description_jp}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CRow>
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="date">Ngày tạo</CLabel>
                    <DatePicker formik={formik} id="date" name="date" />
                  </CFormGroup>
                </CCol>
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="select_new_type">Kiểu bài</CLabel>
                    <CSelect
                      custom
                      name="new_type"
                      id="new_type"
                      value={values.new_type || ''}
                      onChange={e => setData(e.target.name, e.target.value)}
                    >
                      <option disabled selected value="">{` `}</option>
                      {Object.keys(NewType).map(key => (
                        <option key={uuid()} value={NewType[key].value}>
                          {NewType[key].name}
                        </option>
                      ))}
                    </CSelect>
                    {touched.new_type && errors.new_type ? (
                      <small className="text-danger">{errors.new_type}</small>
                    ) : null}
                  </CFormGroup>
                </CCol>
              </CRow>
            </CRow>
            <CRow>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="content_vi">Nội dung tiếng Việt</CLabel>
                  <SunEditorCustom
                    key={initialValues.content_vi}
                    onChange={value => setData('content_vi', value)}
                    name="content_vi"
                    showToolbar
                    height="100"
                    setContents={values.content_vi}
                  />
                  {touched.content_vi && errors.content_vi ? (
                    <small className="text-danger">{errors.content_vi}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="content_en">Nội dung tiếng Anh</CLabel>
                  <SunEditorCustom
                    key={initialValues.content_vi}
                    onChange={value => setData('content_en', value)}
                    name="content_en"
                    showToolbar
                    height="100"
                    setContents={values.content_en}
                  />
                  {touched.content_en && errors.content_en ? (
                    <small className="text-danger">{errors.content_en}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="content_jp">Nội dung tiếng Nhật</CLabel>
                  <SunEditorCustom
                    key={initialValues.content_jp}
                    onChange={value => setData('content_jp', value)}
                    name="content_jp"
                    showToolbar
                    height="100"
                    setContents={values.content_jp}
                  />
                  {touched.content_jp && errors.content_jp ? (
                    <small className="text-danger">{errors.content_jp}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="status"
                    name="status"
                    checked={values.status === 'active' ? 1 : 0}
                    onChange={e =>
                      setData(e.target.name, values.status === 'active' ? 'inactive' : 'active')
                    }
                  />
                  <CLabel htmlFor="status">Kích hoạt</CLabel>
                </CFormGroup>
              </CCol>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="main_post"
                    name="main_post"
                    checked={values.main_post ? 1 : 0}
                    onChange={e => setData(e.target.name, !values.main_post ? 1 : 0)}
                  />
                  <CLabel htmlFor="main_post">Tin chính</CLabel>
                </CFormGroup>
              </CCol>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="popular_new"
                    name="popular_new"
                    checked={values.popular_new ? 1 : 0}
                    onChange={e => setData(e.target.name, !values.popular_new ? 1 : 0)}
                  />
                  <CLabel htmlFor="popular_new">Tin phổ biến</CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter align="center">
            <CButton color="primary" style={{ marginRight: 10 }} type="submit">
              Lưu
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              Không
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style global jsx>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default NewsDetail;
