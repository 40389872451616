const NewType = {
  New: {
    value: 1,
    name: 'Tin tức',
  },
  Recruitment: {
    value: 2,
    name: 'Tuyển dụng',
  },
};

export default NewType;
