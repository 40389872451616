/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CForm,
  CInputCheckbox,
  CSelect,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { fetchConfig } from 'src/slices/ConfigSlice';
import { detailPromotion, upsertPromotion } from 'src/slices/PromotionSlice';
import { resetData } from 'src/slices/PromotionItemSlice';

const PromotionDetail = () => {
  const config = useSelector(state => state.config);
  const routeParams = useParams();
  const { id } = routeParams;
  const [openModalProject, setOpenModalProject] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const promotionItem = useSelector(state => state.promotionItem);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const formik = useFormik({
    initialValues: {
      month: promotionItem.month || '',
      content_vi: promotionItem.content_vi || '',
      content_en: promotionItem.content_en || '',
      content_jp: promotionItem.content_jp || '',
      status: promotionItem.status || 'inactive',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      month: Yup.number().required('Vui lòng chọn thời gian'),
      content_vi: Yup.string().required('Vui lòng điền ưu đãi tiếng Việt'),
    }),
    onSubmit: async vals => {
      const res = await dispatch(
        upsertPromotion({
          id: id === 'create' ? '' : id,
          data: { ...vals },
        }),
      );

      if (res.payload === true) {
        history.push(`/promotions`);
      }
    },
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
    initialValues,
  } = formik;
  const imageChange = e => {
    setSelectedImage(e.target.files[0]);
  };

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    setSelectedImage();
    history.push(`/promotions`);
  };

  useEffect(() => {
    const load = async () => {
      if (id !== 'create') {
        await dispatch(detailPromotion(id));
      } else {
        dispatch(resetData());
        setSelectedImage();
      }
    };

    load();
  }, [id]);

  useEffect(() => {
    dispatch(fetchConfig());
  }, []);

  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
        validated={validated}
      >
        <CCard>
          <CCardHeader>Tạo mới ưu đãi</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="month">Thời gian</CLabel>
                  {!!config?.coursePeriods && (
                    <CSelect
                      custom
                      name="month"
                      id="month"
                      value={values.month}
                      onChange={async e => {
                        await setData('month', +e.target.value);
                      }}
                    >
                      {['Chọn thời gian'].concat(JSON.parse(config?.coursePeriods)).map(item => {
                        return (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        );
                      })}
                    </CSelect>
                  )}
                  {touched.month && errors.month ? (
                    <small className="text-danger">{errors.month}</small>
                  ) : null}
                </CFormGroup>
              </CCol>

              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="content_vi">Nội dung tiếng Việt</CLabel>
                  <CInput
                    id="content_vi"
                    name="content_vi"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.content_vi || ''}
                  />
                  {touched.content_vi && errors.content_vi ? (
                    <small className="text-danger">{errors.content_vi}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="content_en">Nội dung tiếng Anh</CLabel>
                  <CInput
                    id="content_en"
                    name="content_en"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.content_en || ''}
                  />
                  {touched.content_en && errors.content_en ? (
                    <small className="text-danger">{errors.content_en}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="content_jp">Nội dung tiếng Nhật</CLabel>
                  <CInput
                    id="content_jp"
                    name="content_jp"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.content_jp || ''}
                  />
                  {touched.content_jp && errors.content_jp ? (
                    <small className="text-danger">{errors.content_jp}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="status"
                    name="status"
                    checked={values.status === 'active' ? 1 : 0}
                    onChange={e =>
                      setData(e.target.name, values.status === 'active' ? 'inactive' : 'active')
                    }
                  />
                  <CLabel htmlFor="status">Kích hoạt</CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter align="center">
            <CButton color="primary" style={{ marginRight: 10 }} type="submit">
              Lưu
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              Không
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style global jsx>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default PromotionDetail;
