const Status = {
  InActive: {
    value: 'inactive',
    name: 'Không kích hoạt',
  },
  Active: {
    value: 'active',
    name: 'Kích hoạt',
  },
};

export default Status;
