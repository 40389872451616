import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  image: '',
  name: '',
  position: '',
  description_vi: '',
  description_en: '',
  description_jp: '',
  search: '',
  status: '',
  order: '',
  group_trainers: '',
};
export const coachItemSlice = createSlice({
  name: 'coach',
  initialState,
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setDefaultValue: (_, action) => {
      if (!action?.payload?.id) return initialState;
      return { ...action.payload };
    },
    resetData: () => {
      return initialState;
    },
  },
});
export const { setItem, setDefaultValue, resetData } = coachItemSlice.actions;

export default coachItemSlice.reducer;
