import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  image: '',
  name: '',
  description: '',
  link_map: '',
  order: '',
  branch_category_id: '',
  status: '',
  search: '',
};
export const branchItemSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setDefaultValue: (_, action) => {
      if (!action?.payload?.id) return initialState;
      return { ...action.payload };
    },
    resetData: () => {
      return initialState;
    },
  },
});
export const { setItem, setDefaultValue, resetData } = branchItemSlice.actions;

export default branchItemSlice.reducer;
