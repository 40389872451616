/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CForm,
  CInputCheckbox,
  CInputFile,
  CTextarea,
  CSelect,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { fetchConfig } from 'src/slices/ConfigSlice';
import { detailCoach, upsertCoach } from 'src/slices/CoachSlice';
import { resetData } from 'src/slices/CoachItemSlice';
import apiClient from 'src/api/api-client';
import { uploadFileURI } from 'src/api';
import GroupTrainer from 'src/enums/GroupTrainer';

const CoachDetail = () => {
  const config = useSelector(state => state.config);
  const routeParams = useParams();
  const { id } = routeParams;
  const [openModalProject, setOpenModalProject] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const coachItem = useSelector(state => state.coachItem);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: coachItem.name || '',
      position: coachItem.position || '',
      description_vi: coachItem.description_vi || '',
      description_en: coachItem.description_en || '',
      description_jp: coachItem.description_jp || '',
      order: coachItem.order || '',
      status: coachItem.status || 'inactive',
      group_trainers: coachItem.group_trainers || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Vui lòng nhập tên HLV'),
      position: Yup.string().required('Vui lòng điền vị trí'),
    }),
    onSubmit: async vals => {
      let { image } = coachItem;
      if (selectedImage) {
        const uploadFormData = new FormData();
        uploadFormData.append('file', selectedImage);
        const uploadRes = await apiClient.handleRequest({
          method: 'post',
          url: `${uploadFileURI}`,
          data: uploadFormData,
        });
        image = uploadRes.data.data;
      }

      const res = await dispatch(
        upsertCoach({
          id: id === 'create' ? '' : id,
          data: { ...vals, image },
        }),
      );

      if (res.payload === true) {
        history.push(`/coachs`);
      }
    },
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
    initialValues,
  } = formik;
  const imageChange = e => {
    setSelectedImage(e.target.files[0]);
  };

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    setSelectedImage();
    history.push(`/coachs`);
  };

  useEffect(() => {
    const load = async () => {
      if (id !== 'create') {
        await dispatch(detailCoach(id));
      } else {
        dispatch(resetData());
        setSelectedImage();
      }
    };

    load();
  }, [id]);

  useEffect(() => {
    dispatch(fetchConfig());
  }, []);

  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
        validated={validated}
      >
        <CCard>
          <CCardHeader>Tạo mới huấn luyện viên</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="avatar">Tải ảnh</CLabel>
                  <CFormGroup variant="custom-checkbox">
                    <CInputFile
                      custom
                      id="image"
                      onChange={e => {
                        imageChange(e);
                      }}
                      accept="image/*"
                    />
                    <CLabel htmlFor="image" variant="custom-file">
                      {selectedImage?.name || 'Choose file'}
                    </CLabel>
                  </CFormGroup>
                </CFormGroup>
                {selectedImage && (
                  <div>
                    <img
                      style={{ width: '-webkit-fill-available' }}
                      src={URL.createObjectURL(selectedImage)}
                      alt=""
                    />
                  </div>
                )}
                {!selectedImage && coachItem.image && (
                  <div>
                    <img style={{ width: '-webkit-fill-available' }} src={coachItem.image} alt="" />
                  </div>
                )}
              </CCol>

              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="name">Tên</CLabel>
                  <CInput
                    id="name"
                    name="name"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.name || ''}
                  />
                  {touched.name && errors.name ? (
                    <small className="text-danger">{errors.name}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="position">Vị trí</CLabel>
                  <CInput
                    id="position"
                    name="position"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.position || ''}
                  />
                  {touched.position && errors.position ? (
                    <small className="text-danger">{errors.position}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="select_trainner">Nhóm HLV</CLabel>
                  <CSelect
                    custom
                    name="group_trainers"
                    id="group_trainers"
                    value={values.group_trainers}
                    onChange={e => setData(e.target.name, e.target.value)}
                  >
                    {Object.keys(GroupTrainer).map((key, index) => (
                      <option key={index} value={GroupTrainer[key].value}>
                        {GroupTrainer[key].name}
                      </option>
                    ))}
                  </CSelect>
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="order">Thứ tự</CLabel>
                  <CInput
                    type="number"
                    id="order"
                    name="order"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.order || ''}
                  />
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="description_vi">Mô tả tiếng Việt</CLabel>
                  <CTextarea
                    id="description_vi"
                    name="description_vi"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.description_vi || ''}
                  />
                  {touched.description_vi && errors.description_vi ? (
                    <small className="text-danger">{errors.description_vi}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="description_en">Mô tả tiếng Anh</CLabel>
                  <CTextarea
                    id="description_en"
                    name="description_en"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.description_en || ''}
                  />
                  {touched.description_en && errors.description_en ? (
                    <small className="text-danger">{errors.description_en}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="description_jp">Mô tả tiếng Nhật</CLabel>
                  <CTextarea
                    id="description_jp"
                    name="description_jp"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.description_jp || ''}
                  />
                  {touched.description_jp && errors.description_jp ? (
                    <small className="text-danger">{errors.description_jp}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="status"
                    name="status"
                    checked={values.status === 'active' ? 1 : 0}
                    onChange={e =>
                      setData(e.target.name, values.status === 'active' ? 'inactive' : 'active')
                    }
                  />
                  <CLabel htmlFor="status">Kích hoạt</CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter align="center">
            <CButton color="primary" style={{ marginRight: 10 }} type="submit">
              Lưu
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              Không
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style global jsx>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default CoachDetail;
