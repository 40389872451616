/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CDataTable,
  CPagination,
  CFormGroup,
  CLabel,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CButton,
} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import { freeSet } from '@coreui/icons';
import { useDispatch, useSelector } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { fetchHaNoiRegister } from 'src/slices/HanoiSlice';
import { setItem } from 'src/slices/HaNoiRegisterItemSlice';

const RegisterHaNoi = () => {
  const fields = [
    { key: 'id', label: '#' },
    { key: 'name', label: 'Họ tên' },
    { key: 'phone', label: 'Phone' },
    { key: 'quantity', label: 'Số lượng' },
    { key: 'source_information', label: 'Biết tới Genio' },
  ];
  const hanoiRegister = useSelector(state => state.hanoi);
  const HanoiRegisterItem = useSelector(state => state.hanoiItem);

  const dispatch = useDispatch();

  const getBadge = status => {
    switch (status) {
      case 'active':
        return 'success';
      case 'inactive':
        return 'danger';
      default:
        return 'primary';
    }
  };

  const { entities, loading, currentPage, totalPage, total } = hanoiRegister;
  const { status, search } = HanoiRegisterItem;

  useEffect(() => {
    dispatch(fetchHaNoiRegister({ page: currentPage, limit: 10 }));
  }, []);

  const handleSearch = () => {
    dispatch(fetchHaNoiRegister({ search, status, page: 1, limit: 10 }));
  };

  const setFilter = e => {
    const { name, value } = e.target;
    dispatch(setItem({ name, value }));
  };

  return (
    <>
      <CCard>
        <CCardHeader>Danh sách đăng ký</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol lg="11">
              <CFormGroup>
                <CLabel htmlFor="search">Tìm kiếm</CLabel>
                <CInputGroup>
                  <CInput
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Nhập thông tin muốn tìm kiếm"
                    autoComplete="off"
                    onChange={setFilter}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText className="bg-info text-white">
                      <CIcon content={freeSet.cilSearch} />
                    </CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
            <CCol lg="1" style={{ display: 'flex', justifyContent: 'end' }}>
              <CFormGroup>
                <CLabel htmlFor="" style={{ visibility: 'hidden' }}>
                  Hidden
                </CLabel>
                <CInputGroup>
                  <CButton
                    className="mb-2 float-right"
                    color="info"
                    onClick={() => {
                      handleSearch();
                    }}
                  >
                    Search
                  </CButton>
                </CInputGroup>
              </CFormGroup>
            </CCol>
          </CRow>
          {/* <CRow>
            <CCol>
              
            </CCol>
          </CRow> */}
          <CRow>
            <CCol>
              <CCard>
                <CCardBody>
                  <CDataTable
                    items={entities}
                    fields={fields}
                    hover
                    striped
                    bordered
                    size="sm"
                    loading={loading === 'pending'}
                    itemsPerPage={10}
                    activePage={1}
                    scopedSlots={{
                      source_information: item => {
                        const array = JSON.parse(item?.source_information);
                        const text = array.toString();
                        return <td>{text}</td>;
                      },
                    }}
                  />
                  {total > 0 && (
                    <CRow>
                      <CCol>
                        <b>Total: {total}</b>
                      </CCol>
                      <CCol className="text-right">
                        <CPagination
                          activePage={currentPage}
                          pages={totalPage}
                          onActivePageChange={page =>
                            dispatch(fetchHaNoiRegister({ page, limit: 10 }))
                          }
                          align="end"
                        />
                      </CCol>
                    </CRow>
                  )}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};

export default RegisterHaNoi;
