/* eslint-disable prettier/prettier */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import SunEditor from 'suneditor-react';
import apiClient from 'src/api/api-client';
import { uploadFileURI } from 'src/api';
import 'suneditor/dist/css/suneditor.min.css';

export const uploadImage = async image => {
  const payload = new FormData();
  if (image) {
    payload.append('file', image);
    const res = await apiClient.handleRequest({
      method: 'post',
      url: `${uploadFileURI}`,
      data: payload,
    });
    return res.data.data;
  }
  return false;
};

const SunEditorCustom = ({ children, onChange, setContents }) => {
  const handleImageUploadBefore = async (files, info, uploadHandler) => {
    // Upload image to Server

    const src = await uploadImage(files[0]);
    // result
    if (src) {
      const response = {
        // The response must have a "result" array.
        result: [
          {
            url: src,
            name: files[0].name,
            size: files[0].size,
          },
        ],
      };
      uploadHandler(response);
    }
  };
  return (
    <SunEditor
      setOptions={{
        buttonList: [
          // default
          ['undo', 'redo'],
          ['font', 'fontSize', 'formatBlock'],
          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'align'],
          ['image'],
          ['link'],
          ['removeFormat'],
          ['outdent', 'indent'],
          ['fullScreen', 'showBlocks', 'codeView'],
          ['preview', 'print'],
        ],
      }}
      onImageUploadBefore={handleImageUploadBefore}
      onChange={onChange}
      setContents={setContents}
      {...children}
    />
  );
};

export default SunEditorCustom;
