const GroupTrainer = {
  MaleTrainer: {
    value: 1,
    name: 'HLV Nam',
  },
  ForeignTrainer: {
    value: 2,
    name: 'HLV Nước Ngoài',
  },
  FemaleTrainer: {
    value: 3,
    name: 'HLV Nữ',
  },
};

export default GroupTrainer;
