/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CDataTable,
  CBadge,
  CPagination,
  CFormGroup,
  CLabel,
  CInput,
  CInputCheckbox,
  CButton,
  CCollapse,
  CCardFooter,
  CForm,
} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../class.scss';

import {
  fetchTuitions,
  insertTuitions,
  updateTuitions,
  deleteTuition,
} from 'src/slices/TuitionSlice';
import {
  insertCourseClassTuition,
  updateCourseClassTuition,
} from 'src/slices/CourseClassTuitionSlice';
import { fetchClasses, insertUpdateClasses } from 'src/slices/ClassSlice';

import { setValue } from 'src/slices/MessageSlice';

import Modal from './modalComponent';

const Collapse = ({ data, onClickRemove }) => {
  const [collapse, setCollapse] = useState(false);
  const [className, setClassName] = useState(data.classname_vi);
  const [modal, setModal] = useState(false);
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [title, setTitle] = useState('');
  const [tuitionItem, setTuitionItem] = useState({
    course_id: '',
    group_id: '',
    price_vi: '',
    price_en: '',
    price_jp: '',
    status: 'inactive',
  });

  const dispatch = useDispatch();
  const tuition = useSelector(state => state.tuition);

  useEffect(() => {
    if (collapse) {
      dispatch(fetchTuitions({ id: data.id }));
    }
  }, [collapse]);

  const fields = [
    { key: 'id', label: '#' },
    { key: 'price_vi', label: 'Học phí tiếng Việt' },
    { key: 'price_en', label: 'Học phí tiếng Anh' },
    { key: 'price_jp', label: 'Học phí tiếng Nhật' },
    { key: 'group_name_vi', label: 'Nhóm' },
    { key: 'course_month_vi', label: 'Tháng' },
    { key: 'status', label: 'Trạng thái' },
    { key: 'actions', label: 'Thao tác' },
  ];

  const formik = useFormik({
    initialValues: {
      classname_vi: data.classname_vi || '',
      classname_en: data.classname_en || '',
      classname_jp: data.classname_jp || '',
      status: data.status || 'inactive',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      classname_vi: Yup.string().required('Vui lòng điền tên lớp tiếng Việt'),
      classname_en: Yup.string().required('Vui lòng điền tên lớp tiếng Anh'),
      classname_jp: Yup.string().required('Vui lòng điền tên lớp tiếng Nhật'),
    }),
    onSubmit: async vals => {
      const res = await dispatch(
        insertUpdateClasses({
          id: data.id,
          data: { ...vals },
        }),
      );
      if (res.payload === true) {
        dispatch(fetchClasses());
      }
    },
  });

  const {
    handleSubmit,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
  } = formik;

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const { entities, loading, currentPage, totalPage, total } = tuition;

  const toggle = e => {
    setCollapse(!collapse);
    e.preventDefault();
  };

  const getBadge = status => {
    switch (status) {
      case 'active':
        return 'success';
      case 'inactive':
        return 'danger';
      default:
        return 'primary';
    }
  };

  const confirmDelete = item => {
    const tuitionId = item.id;
    const param = {
      tuitionId,
      totalPage,
    };
    confirmAlert({
      title: 'Xác nhận xóa học phí này',
      message: 'Chắc chắn muốn xóa học phí này ?',
      buttons: [
        {
          label: 'Delete',
          className: 'bg-danger',
          onClick: () => {
            dispatch(deleteTuition(param));
          },
        },
        {
          label: 'Cancel',
          onClick: () => {},
        },
      ],
    });
  };

  const handleDeleteClass = classId => {
    confirmAlert({
      title: 'Xác nhận xóa lớp và toàn bộ học phí',
      message: 'Chắc chắn muốn xóa lớp và toàn bộ học phí thuộc class này?',
      buttons: [
        {
          label: 'Delete',
          className: 'bg-danger',
          onClick: () => {
            onClickRemove(classId);
          },
        },
        {
          label: 'Cancel',
          onClick: () => {},
        },
      ],
    });
  };

  const handleClean = e => {
    e.preventDefault();
  };

  const handleUpSertTuition = async (form, statusModal, previousValue) => {
    setModal(statusModal);
    if (form) {
      if (!isCreateModal) {
        const res = await Promise.all([
          dispatch(
            updateTuitions({
              id: form.tuition_id,
              data: {
                price_en: form.price_en,
                price_jp: form.price_jp,
                price_vi: form.price_vi,
                status: form.status,
              },
            }),
          ),
          dispatch(
            updateCourseClassTuition({
              data: {
                class_id: data.id,
                course_id: form.course_id,
                tuition_id: form.tuition_id,
                status: form.status,
                previousData: {
                  course_id: previousValue.course_id,
                },
              },
            }),
          ),
        ]);

        if (res[1].payload) {
          if (res[1].payload.message) {
            dispatch(setValue({ show: true, body: res[1].payload.message }));
          } else {
            dispatch(setValue({ show: true, body: 'Cập nhật thành công', color: 'success' }));
          }

          dispatch(fetchTuitions({ id: data.id }));
        }
      } else {
        const res = await dispatch(
          insertTuitions({
            data: {
              price_en: form.price_en,
              price_jp: form.price_jp,
              price_vi: form.price_vi,
              status: form.status,
            },
          }),
        );

        const res1 = await dispatch(
          insertCourseClassTuition({
            data: {
              class_id: data.id,
              course_id: form.course_id,
              tuition_id: res.payload.id,
              group_id: form.group_id,
              status: form.status,
            },
          }),
        );

        if (res1.payload) {
          if (res1.payload.message) {
            dispatch(setValue({ show: true, body: res1.payload.message }));
          } else {
            dispatch(setValue({ show: true, body: 'Thêm mới thành công', color: 'success' }));
          }

          dispatch(fetchTuitions({ id: data.id }));
        }
      }
    }
  };

  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
      >
        <CCard>
          <CCardHeader
            onClick={e => {
              if (
                e.target.classList.contains('header-content') ||
                e.target.classList.contains('card-header')
              ) {
                toggle(e);
              }
            }}
          >
            <div className="d-flex align-items-center justify-content-between header-content">
              <div>{className}</div>
              <span
                className="btn-class"
                onClick={e => {
                  handleDeleteClass(data.id);
                }}
                color="primary"
              >
                <CIcon name="cilTrash" />
              </span>
            </div>
          </CCardHeader>
          <CCollapse show={collapse}>
            <CCardBody>
              <CRow>
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="classname_vi">Tên lớp tiếng Việt</CLabel>
                    <CInput
                      id="classname_vi"
                      name="classname_vi"
                      onChange={e => {
                        setData(e.target.name, e.target.value);
                        setClassName(e.target.value);
                      }}
                      value={values.classname_vi || ''}
                    />
                    {touched.classname_vi && errors.classname_vi ? (
                      <small className="text-danger">{errors.classname_vi}</small>
                    ) : null}
                  </CFormGroup>
                </CCol>
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="classname_en">Tên lớp tiếng Anh</CLabel>
                    <CInput
                      id="classname_en"
                      name="classname_en"
                      onChange={e => setData(e.target.name, e.target.value)}
                      value={values.classname_en || ''}
                    />
                    {touched.classname_en && errors.classname_en ? (
                      <small className="text-danger">{errors.classname_en}</small>
                    ) : null}
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol xs="6">
                  <CFormGroup>
                    <CLabel htmlFor="classname_jp">Tên lớp tiếng Nhật</CLabel>
                    <CInput
                      id="classname_jp"
                      name="classname_jp"
                      onChange={e => setData(e.target.name, e.target.value)}
                      value={values.classname_jp || ''}
                    />
                    {touched.classname_jp && errors.classname_jp ? (
                      <small className="text-danger">{errors.classname_jp}</small>
                    ) : null}
                  </CFormGroup>
                </CCol>
                <CCol xs="6" className="d-flex align-self-center">
                  <CFormGroup variant="custom-checkbox" inline>
                    <CInputCheckbox
                      id="status"
                      name="status"
                      checked={values.status === 'active' ? 1 : 0}
                      onChange={e =>
                        setData(e.target.name, values.status === 'active' ? 'inactive' : 'active')
                      }
                    />
                    <CLabel htmlFor="status">Kích hoạt</CLabel>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol xs="12">
                  <CCard>
                    <CCardHeader
                      style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                      className="d-flex justify-content-center"
                    >
                      Học phí theo tháng của từng lớp
                    </CCardHeader>
                    <CCardBody>
                      <CButton
                        onClick={() => {
                          setTitle('Tạo mới học phí');
                          setTuitionItem({
                            course_id: '',
                            group_id: '',
                            price_vi: '',
                            price_en: '',
                            price_jp: '',
                            status: 'inactive',
                          });
                          setModal(true);
                          setIsCreateModal(true);
                        }}
                        className="mb-1"
                        color="primary"
                      >
                        <CIcon name="cilPlus" />
                      </CButton>
                      <CDataTable
                        items={entities}
                        fields={fields}
                        hover
                        striped
                        bordered
                        size="sm"
                        loading={loading === 'pending'}
                        itemsPerPage={10}
                        activePage={1}
                        scopedSlots={{
                          status: item => (
                            <td>
                              <CBadge
                                className="pointer"
                                role="button"
                                color={getBadge(item.status)}
                              >
                                {item.status}
                              </CBadge>
                            </td>
                          ),
                          actions: item => (
                            <td>
                              <span
                                type="button"
                                onClick={() => {
                                  setTitle('Chỉnh sửa học phí');
                                  setTuitionItem(item);
                                  setModal(true);
                                  setIsCreateModal(false);
                                }}
                                className="pointer"
                              >
                                <CIcon name="cilPencil" className="text-warning" />
                              </span>
                              <span
                                type="button"
                                className="ml-2"
                                onClick={() => confirmDelete(item)}
                              >
                                <CIcon name="cilTrash" className="text-danger" />
                              </span>
                            </td>
                          ),
                        }}
                      />

                      {total > 0 && (
                        <CRow>
                          <CCol>
                            <b>Total: {total}</b>
                          </CCol>
                          <CCol className="text-right">
                            <CPagination
                              activePage={currentPage}
                              pages={totalPage}
                              onActivePageChange={page =>
                                dispatch(fetchTuitions({ page, limit: 10 }))
                              }
                              align="end"
                            />
                          </CCol>
                        </CRow>
                      )}
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter className="d-flex justify-content-center">
              <CButton color="primary" style={{ marginRight: 10 }} type="submit">
                Lưu
              </CButton>
              <CButton color="secondary" onClick={handleClean}>
                Không
              </CButton>
            </CCardFooter>
          </CCollapse>
        </CCard>
      </CForm>
      {!modal ? (
        ''
      ) : (
        <Modal
          isOn={modal}
          tuitionItem={tuitionItem}
          title={title}
          onGetData={handleUpSertTuition}
        />
      )}
    </>
  );
};

export default Collapse;
