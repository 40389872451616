import React, { useState, useEffect } from 'react';
import { CFormGroup, CLabel, CSwitch } from '@coreui/react';

const GroupRadioButton = ({
  onCallBack,
  initialCallBack,
  branchCategoryItems,
  branchItems,
  branchId,
}) => {
  const [branchFilter, setBranchFilter] = useState();

  const findBranch = idBranch => {
    if (idBranch !== '') {
      const branchCategoryId = branchItems?.entities?.find(s => s.id === Number(idBranch))
        .branch_category_id;
      return branchCategoryId;
    }
    return 0;
  };

  useEffect(() => {
    setBranchFilter(findBranch(branchId));
    onCallBack(findBranch(branchId));
  }, [branchId]);

  return (
    <>
      {branchCategoryItems?.length > 0 &&
        branchCategoryItems?.map((k, index) => {
          return (
            <CFormGroup key={k.id} className="d-flex align-items-center">
              <CSwitch
                id={k.id}
                type="radio"
                color="primary"
                name="fav_language"
                value={k.id}
                onChange={e => {
                  setBranchFilter(k.id);
                  onCallBack(k.id);
                }}
                checked={branchFilter === k.id}
              />
              <CLabel style={{ marginLeft: '10px', marginBottom: '0' }} htmlFor={k.id}>
                {k.name}
              </CLabel>
            </CFormGroup>
          );
        })}
    </>
  );
};

export default React.memo(GroupRadioButton);
