import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  phone: '',
  quantity: '',
  search: '',
};
export const hanoiRegisterItemSlice = createSlice({
  name: 'hanoi',
  initialState,
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setDefaultValue: (_, action) => {
      if (!action?.payload?.id) return initialState;
      return { ...action.payload };
    },
    resetData: () => {
      return initialState;
    },
  },
});
export const { setItem, setDefaultValue, resetData } = hanoiRegisterItemSlice.actions;

export default hanoiRegisterItemSlice.reducer;
