/* eslint-disable react/jsx-curly-newline */
/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import {
  CRow,
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CInputCheckbox,
  CButton,
  CSelect,
  CForm,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from '@coreui/react';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { fetchGroup } from 'src/slices/GroupSlice';
import { fetchCourses } from 'src/slices/CourseSlice';

const Modal = ({ isOn, tuitionItem, title, onGetData }) => {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const group = useSelector(state => state.group);
  const course = useSelector(state => state.course);

  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    dispatch(fetchGroup());
    dispatch(fetchCourses());
  }, []);

  useEffect(() => {
    if (isOn) {
      toggleModal();
    }
  }, [isOn]);

  const formik = useFormik({
    initialValues: {
      course_id: tuitionItem.course_id || '',
      group_id: tuitionItem.group_id || '',
      price_vi: tuitionItem.price_vi || '',
      price_en: tuitionItem.price_en || '',
      price_jp: tuitionItem.price_jp || '',
      status: tuitionItem.status || 'inactive',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      group_id: Yup.string().required('Vui lòng chọn nhóm'),
      course_id: Yup.string().required('Vui lòng chọn khóa'),
      price_vi: Yup.string().required('Vui lòng điền giá tiếng Việt'),
      price_en: Yup.string().required('Vui lòng điền giá tiếng Nhật'),
      price_jp: Yup.string().required('Vui lòng điền giá tiếng Anh'),
    }),
    onSubmit: async vals => {
      toggleModal();
      onGetData({ ...vals, tuition_id: tuitionItem.id }, false, tuitionItem);
    },
  });

  const {
    handleReset,
    handleSubmit,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
  } = formik;

  const setDataModal = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
  };

  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
      >
        <CModal show={modal} closeOnBackdrop={false}>
          <CModalHeader closeButton={false}>{title}</CModalHeader>
          <CModalBody>
            <CRow>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="group_id">Loại nhóm</CLabel>
                  <CSelect
                    custom
                    name="group_id"
                    id="group_id"
                    value={values?.group_id || ''}
                    onChange={e => setDataModal(e.target.name, parseInt(e.target.value))}
                  >
                    <option value="">-</option>
                    {group.entities.map((k, index) => (
                      <option key={uuidv4()} value={k.id}>
                        {k.group_name_vi}
                      </option>
                    ))}
                  </CSelect>
                  {touched.group_id && errors.group_id ? (
                    <small className="text-danger">{errors.group_id}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="course_id">Loại khóa</CLabel>
                  <CSelect
                    custom
                    name="course_id"
                    id="course_id"
                    value={values.course_id || ''}
                    onChange={e => setDataModal(e.target.name, parseInt(e.target.value))}
                  >
                    <option value="">-</option>
                    {course.entities
                      .filter(s => s.group_id === values.group_id)
                      .map((k, index) => (
                        <option key={uuidv4()} value={k.id}>
                          {k.course_month_vi}
                        </option>
                      ))}
                  </CSelect>
                  {touched.course_id && errors.course_id ? (
                    <small className="text-danger">{errors.course_id}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="price_vi">Học phí tiếng Việt</CLabel>
                  <CInput
                    id="price_vi"
                    name="price_vi"
                    onChange={e => setDataModal(e.target.name, e.target.value)}
                    value={values.price_vi || ''}
                  />
                  {touched.price_vi && errors.price_vi ? (
                    <small className="text-danger">{errors.price_vi}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="price_en">Học phí tiếng Anh</CLabel>
                  <CInput
                    id="price_en"
                    name="price_en"
                    onChange={e => setDataModal(e.target.name, e.target.value)}
                    value={values.price_en || ''}
                  />
                  {touched.price_en && errors.price_en ? (
                    <small className="text-danger">{errors.price_en}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="price_jp">Học phí tiếng Nhật</CLabel>
                  <CInput
                    id="price_jp"
                    name="price_jp"
                    onChange={e => setDataModal(e.target.name, e.target.value)}
                    value={values.price_jp || ''}
                  />
                  {touched.price_jp && errors.price_jp ? (
                    <small className="text-danger">{errors.price_jp}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="status_modal"
                    name="status_modal"
                    checked={values.status === 'active' ? 1 : 0}
                    onChange={e =>
                      setDataModal('status', values.status === 'active' ? 'inactive' : 'active')
                    }
                  />
                  <CLabel htmlFor="status_modal">Kích hoạt</CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" onClick={handleSubmit}>
              Lưu
            </CButton>
            <CButton
              color="secondary"
              onClick={e => {
                toggleModal();
                handleClean(e);
                onGetData(null, false);
                handleReset();
              }}
            >
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
      </CForm>
    </>
  );
};

export default Modal;
