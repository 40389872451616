/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CDataTable,
  CBadge,
  CPagination,
  CFormGroup,
  CLabel,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CButton,
  CSelect,
} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import { freeSet } from '@coreui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Status from 'src/enums/Status';
import { fetchGroup, deleteGroup } from 'src/slices/GroupSlice';
import { setItem } from 'src/slices/GroupItemSlice';

const Groups = () => {
  const fields = [
    { key: 'id', label: '#' },
    { key: 'group_name_vi', label: 'Tên nhóm tiếng Việt' },
    { key: 'group_name_en', label: 'Tên nhóm tiếng Anh' },
    { key: 'group_name_jp', label: 'Tên nhóm tiếng Nhật' },
    { key: 'status', label: 'Trạng thái' },
    { key: 'actions', label: 'Thao tác' },
  ];

  const group = useSelector(state => state.group);
  const groupItem = useSelector(state => state.groupItem);

  const dispatch = useDispatch();
  const history = useHistory();

  const getBadge = status => {
    switch (status) {
      case 'active':
        return 'success';
      case 'inactive':
        return 'danger';
      default:
        return 'primary';
    }
  };

  const handleDelete = item => {
    dispatch(deleteGroup({ ...item }));
  };

  const confirmDelete = item => {
    confirmAlert({
      title: 'Xác nhận xóa nhóm',
      message: 'Chắc chắn muốn xóa nhóm này?',
      buttons: [
        {
          label: 'Delete',
          className: 'bg-danger',
          onClick: () => {
            handleDelete(item);
          },
        },
        {
          label: 'Cancel',
          onClick: () => {},
        },
      ],
    });
  };

  const { entities, loading, currentPage, totalPage, total } = group;
  const { status, search } = groupItem;

  useEffect(() => {
    dispatch(fetchGroup({ page: currentPage, limit: 10 }));
  }, []);

  const handleSearch = () => {
    dispatch(
      fetchGroup({
        search,
        status,
        page: 1,
        limit: 10,
      }),
    );
  };

  const setFilter = e => {
    const { name, value } = e.target;
    dispatch(setItem({ name, value }));
  };

  const navigateServiceDetail = id => {
    history.push(`groups/${id}`);
  };

  return (
    <>
      <CCard>
        <CCardHeader>Danh sách nhóm</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol md="4">
              <CFormGroup>
                <CLabel htmlFor="search">Tìm kiếm</CLabel>
                <CInputGroup>
                  <CInput
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Nhập thông tin muốn tìm kiếm"
                    autoComplete="off"
                    onChange={setFilter}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText className="bg-info text-white">
                      <CIcon content={freeSet.cilSearch} />
                    </CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>

            <CCol md="4">
              <CFormGroup>
                <CLabel htmlFor="select_status">Trạng thái</CLabel>
                <CSelect custom name="status" id="status" value={status || ''} onChange={setFilter}>
                  <option value="">Tất cả</option>
                  {Object.keys(Status).map(key => (
                    <option value={Status[key].value}>{Status[key].name}</option>
                  ))}
                </CSelect>
              </CFormGroup>
            </CCol>

            <CCol md="4" className="d-flex justify-content-start">
              <CFormGroup>
                <CLabel style={{ visibility: 'hidden', display: 'inherit' }} htmlFor="button">
                  Button
                </CLabel>
                <CButton
                  className="mb-2 float-right"
                  color="info"
                  onClick={() => {
                    handleSearch();
                  }}
                >
                  Search
                </CButton>
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CCard>
                <CCardBody>
                  <CDataTable
                    items={entities}
                    fields={fields}
                    hover
                    striped
                    bordered
                    size="sm"
                    loading={loading === 'pending'}
                    itemsPerPage={10}
                    activePage={1}
                    scopedSlots={{
                      date: item => (
                        <td>{item.date && new Date(item.date).toLocaleDateString()}</td>
                      ),
                      status: item => (
                        <td>
                          <CBadge className="pointer" role="button" color={getBadge(item.status)}>
                            {item.status}
                          </CBadge>
                        </td>
                      ),
                      actions: item => (
                        <td>
                          <span
                            onClick={() => {
                              navigateServiceDetail(item.id);
                            }}
                            className="pointer"
                            role="button"
                          >
                            <CIcon name="cilPencil" className="text-warning" />
                          </span>
                          <span className="ml-2" role="button" onClick={() => confirmDelete(item)}>
                            <CIcon name="cilTrash" className="text-danger" />
                          </span>
                        </td>
                      ),
                    }}
                  />
                  {total > 0 && (
                    <CRow>
                      <CCol>
                        <b>Total: {total}</b>
                      </CCol>
                      <CCol className="text-right">
                        <CPagination
                          activePage={currentPage}
                          pages={totalPage}
                          onActivePageChange={page => dispatch(fetchGroup({ page, limit: 10 }))}
                          align="end"
                        />
                      </CCol>
                    </CRow>
                  )}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};

export default Groups;
