import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  group_name_vi: '',
  group_name_en: '',
  group_name_jp: '',
  search: '',
  status: '',
};
export const groupItemSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setItem: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setDefaultValue: (_, action) => {
      if (!action?.payload?.id) return initialState;
      return { ...action.payload };
    },
    resetData: () => {
      return initialState;
    },
  },
});
export const { setItem, setDefaultValue, resetData } = groupItemSlice.actions;

export default groupItemSlice.reducer;
