/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CDataTable,
  CBadge,
  CPagination,
  CFormGroup,
  CLabel,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CButton,
  CSelect,
} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import { freeSet } from '@coreui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { deleteNews, fetchNews } from 'src/slices/NewsSlice';
import { setItem } from 'src/slices/NewsItemSlice';
import Status from 'src/enums/Status';
import dayjs from 'dayjs';
import NewType from 'src/enums/NewType';

const News = () => {
  const fields = [
    { key: 'title_vi', label: 'Tiêu đề tiếng Việt' },
    { key: 'status', label: 'Trạng thái' },
    { key: 'date', label: 'Thời gian' },
    { key: 'author', label: 'Tác giả' },
    { key: 'name_type', label: 'Loại bài' },
    { key: 'actions', label: 'Thao tác' },
  ];

  const news = useSelector(state => state.news);
  const newsItem = useSelector(state => state.newsItem);

  const dispatch = useDispatch();
  const history = useHistory();

  const getBadge = status => {
    switch (status) {
      case 'active':
        return 'success';
      case 'inactive':
        return 'danger';
      default:
        return 'primary';
    }
  };

  const handleDelete = item => {
    dispatch(deleteNews({ ...item }));
  };

  const confirmDelete = item => {
    confirmAlert({
      title: 'Xác nhận xóa tin tức',
      message: 'Chắc chắn muốn xóa tin tức này?',
      buttons: [
        {
          label: 'Delete',
          className: 'bg-danger',
          onClick: () => {
            handleDelete(item);
          },
        },
        {
          label: 'Cancel',
          onClick: () => {},
        },
      ],
    });
  };

  const { entities, loading, currentPage, totalPage, total } = news;
  const { status, search, date, new_type } = newsItem;

  useEffect(() => {
    dispatch(fetchNews({ page: currentPage, limit: 10 }));
  }, []);

  const handleSearch = () => {
    dispatch(
      fetchNews({
        search,
        date: date !== '' ? dayjs(date).format('YYYY-MM-DD') : '',
        status,
        page: 1,
        limit: 10,
        new_type,
      }),
    );
  };

  const setFilter = e => {
    const { name, value } = e.target;
    dispatch(setItem({ name, value }));
  };

  const navigateServiceDetail = id => {
    history.push(`news/${id}`);
    // dispatch(
    //   NavigationActions.navigate({
    //     routeName: ,
    //   }),
    // );
  };

  return (
    <>
      <CCard>
        <CCardHeader>Danh sách đăng ký</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="search">Tìm kiếm</CLabel>
                <CInputGroup>
                  <CInput
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Nhập thông tin muốn tìm kiếm"
                    autoComplete="off"
                    onChange={setFilter}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText className="bg-info text-white">
                      <CIcon content={freeSet.cilSearch} />
                    </CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>

            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="select_status">Trạng thái</CLabel>
                <CSelect custom name="status" id="status" value={status || ''} onChange={setFilter}>
                  <option value="">Tất cả</option>
                  {Object.keys(Status).map(key => (
                    <option value={Status[key].value}>{Status[key].name}</option>
                  ))}
                </CSelect>
              </CFormGroup>
            </CCol>

            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="select_new_type">Kiểu bài</CLabel>
                <CSelect
                  custom
                  name="new_type"
                  id="new_type"
                  value={new_type || ''}
                  onChange={setFilter}
                >
                  <option value="">Tất cả</option>
                  {Object.keys(NewType).map(key => (
                    <option value={NewType[key].value}>{NewType[key].name}</option>
                  ))}
                </CSelect>
              </CFormGroup>
            </CCol>

            <CCol md="3">
              <CFormGroup>
                <CLabel htmlFor="date">Thời gian</CLabel>
                <CInput
                  type="date"
                  name="date"
                  id="date"
                  value={newsItem.date || ''}
                  onChange={setFilter}
                />
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CButton
                className="mb-2 float-right"
                color="info"
                onClick={() => {
                  handleSearch();
                }}
              >
                Search
              </CButton>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CCard>
                <CCardBody>
                  <CDataTable
                    items={entities}
                    fields={fields}
                    hover
                    striped
                    bordered
                    size="sm"
                    loading={loading === 'pending'}
                    itemsPerPage={10}
                    activePage={1}
                    scopedSlots={{
                      date: item => (
                        <td>{item.date && new Date(item.date).toLocaleDateString()}</td>
                      ),
                      status: item => (
                        <td>
                          <CBadge className="pointer" role="button" color={getBadge(item.status)}>
                            {item.status}
                          </CBadge>
                        </td>
                      ),
                      actions: item => (
                        <td>
                          <span
                            onClick={() => {
                              navigateServiceDetail(item.id);
                            }}
                            className="pointer"
                            role="button"
                          >
                            <CIcon name="cilPencil" className="text-warning" />
                          </span>
                          <span className="ml-2" role="button" onClick={() => confirmDelete(item)}>
                            <CIcon name="cilTrash" className="text-danger" />
                          </span>
                        </td>
                      ),
                    }}
                  />
                  {total > 0 && (
                    <CRow>
                      <CCol>
                        <b>Total: {total}</b>
                      </CCol>
                      <CCol className="text-right">
                        <CPagination
                          activePage={currentPage}
                          pages={totalPage}
                          onActivePageChange={page => dispatch(fetchNews({ page, limit: 10 }))}
                          align="end"
                        />
                      </CCol>
                    </CRow>
                  )}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};

export default News;
