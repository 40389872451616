/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-curly-newline */
/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CForm,
  CSelect,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { detailRegistration, upsertRegistration } from 'src/slices/RegistrationSlice';
import { fetchBranchCategories } from 'src/slices/BranchCategorySlice';
import { fetchBranch } from 'src/slices/BranchSlice';
import { resetData } from 'src/slices/RegistrationItemSlice';
import ChildForm from 'src/components/Registration/ChildForm';
import GroupRadioButton from './GroupRadioButton';

const RegistrationDetail = () => {
  const routeParams = useParams();

  const [openModalProject, setOpenModalProject] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [validated, setValidated] = useState(false);
  const [branchCategoryId, setBranchCategoryId] = useState();
  const [listener, setListener] = useState();

  const dispatch = useDispatch();
  const history = useHistory();

  const branchCategory = useSelector(state => state.branchCategory);
  const branch = useSelector(state => state.branch);
  const item = useSelector(state => state.registrationItem);

  const childrenRefs = useRef([]);

  const { id } = routeParams;
  const { entities } = branchCategory;

  useEffect(() => {
    dispatch(fetchBranchCategories({ limit: 999999 }));
    dispatch(fetchBranch({ limit: 999999 }));
  }, []);

  useEffect(() => {
    const load = async () => {
      if (id !== 'create') {
        await dispatch(detailRegistration(id));
      } else {
        dispatch(resetData());
        setSelectedImage();
      }
    };

    load();
  }, [id]);

  const formik = useFormik({
    initialValues: {
      parent_name: item.parent_name || '',
      parent_email: item.parent_email || '',
      parent_phone: item.parent_phone || '',
      address: item.address || '',
      child: item.childrens || [{}],
      branch_id: item.branch_id || '',

      // child_name: item.child_name || '',
      // child_birthday: item.child_birthday || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      parent_name: Yup.string().required('Vui lòng điền họ tên'),
      // parent_email: Yup.string().required('Vui lòng điền email'),
      parent_phone: Yup.string().required('Vui lòng điền số điện thoại'),
      // address: Yup.string().required('Vui lòng điền địa chỉ'),
      // child_name: Yup.string().required('Vui lòng điền tên của bé'),
      // child_birthday: Yup.number().required('Vui lòng điền năm sinh của bé'),
    }),
    onSubmit: async vals => {
      const children = childrenRefs.current.filter(child => !!child);
      if (children.every(child => child.formik.isValid)) {
        const res = await dispatch(
          upsertRegistration({
            id: id === 'create' ? '' : id,
            data: { ...vals, child: children.map(child => child.formik.values) },
          }),
        );
        if (res.payload === true) {
          history.push(`/registrations`);
        }
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
  } = formik;
  const imageChange = e => {
    setSelectedImage(e.target.files[0]);
  };

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    setSelectedImage();
    history.push(`/registrations`);
  };

  const onHandleChangeSelected = branchCatId => {
    setBranchCategoryId(branchCatId);
  };

  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        // onSubmit={handleSubmit}
        onSubmit={e => {
          e.preventDefault();
          formik.handleSubmit();
          childrenRefs.current.forEach(child => child?.formik?.handleSubmit());
        }}
      >
        <CCard>
          <CCardHeader>Đăng ký khóa học</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="parent_name">Tên phụ huynh</CLabel>
                  <CInput
                    id="parent_name"
                    name="parent_name"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.parent_name || ''}
                  />
                  {touched.parent_name && errors.parent_name ? (
                    <small className="text-danger">{errors.parent_name}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="parent_email">Địa chỉ Email</CLabel>
                  <CInput
                    id="parent_email"
                    name="parent_email"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.parent_email || ''}
                  />
                  {touched.parent_email && errors.parent_email ? (
                    <small className="text-danger">{errors.parent_email}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="parent_phone">Số điện thoại phụ huynh</CLabel>
                  <CInput
                    id="parent_phone"
                    name="parent_phone"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.parent_phone || ''}
                  />
                  {touched.parent_phone && errors.parent_phone ? (
                    <small className="text-danger">{errors.parent_phone}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="address">Địa chỉ</CLabel>
                  <CInput
                    id="address"
                    name="address"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.address || ''}
                  />
                  {touched.address && errors.address ? (
                    <small className="text-danger">{errors.address}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <GroupRadioButton
                  onCallBack={onHandleChangeSelected}
                  branchCategoryItems={entities}
                  branchItems={branch}
                  branchId={values.branch_id}
                />

                <CSelect
                  custom
                  name="branch_id"
                  id="branch_id"
                  value={values.branch_id}
                  onChange={e => {
                    setData(e.target.name, e.target.value);
                  }}
                >
                  <option value="" hidden="hidden">
                    Hãy chọn 1 cơ sở
                  </option>
                  {branch?.entities
                    .filter(s => s.branch_category_id === branchCategoryId)
                    .map((key, index) => (
                      <option key={index} value={key.id}>
                        {key.name}
                      </option>
                    ))}
                </CSelect>
                {/* {touched.address && errors.address ? (
                    <small className="text-danger">{errors.address}</small>
                  ) : null} */}
              </CCol>
            </CRow>
            {formik.values.child.map((child, index, arr) => (
              <div key={index}>
                <ChildForm
                  defaultData={child}
                  ref={ref => {
                    childrenRefs.current[index] = ref;
                  }}
                  onRemove={() => {
                    childrenRefs.current.splice(index, 1);
                    childrenRefs.current = childrenRefs.current.filter(ref => !!ref);
                    formik.setFieldValue('child', [
                      ...formik.values.child.filter((_, idx) => idx !== index),
                    ]);
                  }}
                  hasRemove={arr.length > 1}
                />
              </div>
            ))}
            {/* <button
              type="button"
              className="mt-8 py-2 px-4 rounded-full flex items-center space-x-2"
              style={{
                background: 'rgba(206, 206, 206, 0.4)',
              }}
              onClick={() => formik.setFieldValue('child', [...formik.values.child, {}])}
            >
              <i className="fa-solid fa-plus text-white" /> <p className="text-white">Thêm bé</p>
            </button> */}
            <CButton
              color="primary"
              style={{ marginTop: 10 }}
              type="button"
              onClick={() => formik.setFieldValue('child', [...formik.values.child, {}])}
            >
              Thêm Bé
            </CButton>
            {/* <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="child_name">Họ tên bé</CLabel>
                  <CInput
                    id="child_name"
                    name="child_name"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.child_name || ''}
                  />
                  {touched.child_name && errors.child_name ? (
                    <small className="text-danger">{errors.child_name}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="child_birthday">Năm sinh</CLabel>
                  <CInput
                    type="number"
                    id="child_birthday"
                    name="child_birthday"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.child_birthday || ''}
                  />
                  {touched.child_birthday && errors.child_birthday ? (
                    <small className="text-danger">{errors.child_birthday}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow> */}
          </CCardBody>
          <CCardFooter align="center">
            <CButton color="primary" style={{ marginRight: 10 }} type="submit">
              Lưu
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              Không
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style global jsx>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default RegistrationDetail;
