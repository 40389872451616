import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { CInput } from '@coreui/react';

import 'react-datepicker/dist/react-datepicker.css';

function DatePicker({
  id,
  icon,
  formik,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  disabled,
  className,
  ...rest
}) {
  const [startDate, setStartDate] = useState(formik?.values?.[name] || value);
  const getYear = date => date.getFullYear();
  const getMonth = date => date.getMonth();
  const range = (from, to, step) => Array.from({ length: to - from }, (_, i) => i + from);
  const years = range(1930, getYear(new Date()) + 1, 1);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  useEffect(() => {
    if (formik && formik?.values?.[name] !== startDate) setStartDate(formik?.values?.[name]);
    else if (value && value !== startDate) setStartDate(value);
  }, [formik?.values, formik?.values.name, name, startDate, value]);

  const handleChange = val => {
    // setStartDate(val || '')

    formik?.setFieldValue(name, val);
    formik?.setFieldTouched(name);
    formik?.validateField(name);
    if (onChange) onChange(val);
  };

  const handleBlur = e => {
    if (formik) {
      formik.setFieldValue(name, startDate);
      formik.handleBlur(e);
    }
    if (onBlur) onBlur();
  };

  const handleSelect = val => {
    formik?.setFieldValue(name, val);
    formik?.setFieldTouched(name);
    formik?.validateField(name);
  };

  return (
    <div>
      <ReactDatePicker
        {...rest}
        disabled={disabled}
        wrapperClassName="w-full"
        customInput={<CInput />}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex px-2" style={{ zIndex: '10' }}>
            {/* <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1 px-3 border border-gray-400 rounded shadow focus:outline-none border-r-0 rounded-r-none"
            >
              <i className="fas fa-chevron-left" />
            </button> */}
            <div className="flex">
              <select
                value={months[getMonth(date)]}
                onBlur
                onChange={e => changeMonth(months.indexOf(e.target.value))}
                className="py-0"
              >
                {months.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <select
                value={getYear(date)}
                onBlur
                onChange={e => changeYear(e.target.value)}
                className="py-0"
              >
                {years.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            {/* <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1 px-3 border border-gray-400 rounded shadow focus:outline-none border-l-0 rounded-l-none"
            >
              <i className="fas fa-chevron-right" />
            </button> */}
          </div>
        )}
        selected={startDate}
        onChange={date => handleChange(date)}
        dateFormat="dd/MM/yyyy"
        //   value={formik?.values?.[name]}
        //   onChange={formik?.handleChange}
        onBlur={handleBlur}
        onSelect={handleSelect}
        id={id}
      />
      {formik?.touched?.[name] && formik?.errors?.[name] ? (
        <small className="text-danger">{formik?.errors?.[name]}</small>
      ) : null}
    </div>
  );
}

export default DatePicker;
