import React from 'react';
import Activities from './views/pages/activity/Activity';
import ActivitiesDetail from './views/pages/activity/ActivityDetail';
import Course from './views/pages/courses/Courses';
import CourseDetail from './views/pages/courses/CoursesDetail';
import News from './views/pages/news/News';
import NewsDetail from './views/pages/news/NewsDetail';
import Registration from './views/pages/registration/Registration';
import TourRegistration from './views/pages/tour/TourRegistration';
import RegistrationDetail from './views/pages/registration/RegistrationDetail';
import Reviews from './views/pages/reviews/Reviews';
import ReviewsDetail from './views/pages/reviews/ReviewsDetail';

import Groups from './views/pages/groups/Group';
import GroupDetail from './views/pages/groups/GroupDetail';

import Promotions from './views/pages/promotion/Promotions';
import PromotionDetail from './views/pages/promotion/PromotionsDetail';
import Coach from './views/pages/coach/Coach';
import CoachDetail from './views/pages/coach/CoachDetail';
import RegisterHaNoi from './views/pages/registerHaNoi/RegisterHaNoi';

import BranchCategory from './views/pages/branchCategory/BranchCategory';
import BranchCategoryDetail from './views/pages/branchCategory/BranchCategoryDetail';

import Branch from './views/pages/branch/Branch';
import BranchDetail from './views/pages/branch/BranchDetail';

import Classes from './views/pages/classes/Class';

const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const Dashboard = React.lazy(() => import('./views/pages/dashboard/Dashboard'));
const PotentialCandidate = React.lazy(() =>
  import('./views/pages/potentialCandidate/PotentialCandidate'),
);
const JobManagerment = React.lazy(() => import('./views/pages/jobManagerment/JobManagerment'));
const CvManagerment = React.lazy(() => import('./views/pages/cvManagerment/CvManagerment'));
const ConfigManagerment = React.lazy(() => import('./views/pages/config/Config'));
const BodManagement = React.lazy(() => import('./views/pages/bodManagerment/BodManagerment'));
const ContactUsManagement = React.lazy(() => import('./views/pages/contactUs/ContactUs'));
const IntroductionManagement = React.lazy(() =>
  import('./views/pages/introductionManagement/IntroductionManagement'),
);
const IntroductionDetailManagement = React.lazy(() =>
  import('./views/pages/introductionManagement/IntroductionManagementDetail'),
);

const NewManagement = React.lazy(() => import('./views/pages/newManagement/NewManagement'));
const ServiceManagement = React.lazy(() =>
  import('./views/pages/serviceManagement/ServiceManagement'),
);
const ServiceManagementDetail = React.lazy(() =>
  import('./views/pages/serviceManagement/ServiceManagementDetail'),
);
const allRoutes = [
  {
    path: '/dashboard',
    key: 'dashboard',
    name: 'Dashboard',
    component: Dashboard,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/potential-candidate',
    name: 'Potential Candidate',
    component: PotentialCandidate,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: ContactUsManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/job-management',
    name: 'Jobs',
    component: JobManagerment,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/cv-management',
    name: 'CV',
    component: CvManagerment,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/config',
    name: 'Config',
    component: ConfigManagerment,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/bod-management',
    name: 'BOD',
    component: BodManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/service-management',
    name: 'Service',
    component: ServiceManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/service-management/:id',
    name: 'Service',
    component: ServiceManagementDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/reviews',
    name: 'Danh sách đánh giá',
    component: Reviews,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/reviews/:id',
    name: 'Tạo đánh giá',
    component: ReviewsDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/courses',
    name: 'Danh sách khóa học',
    component: Course,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/courses/:id',
    name: 'Tạo khóa học',
    component: CourseDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/groups',
    name: 'Danh sách học phí',
    component: Groups,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/groups/:id',
    name: 'Tạo nhóm',
    component: GroupDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/classes',
    name: 'Quản lí lớp',
    component: Classes,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/activities',
    name: 'Danh sách hoạt động',
    component: Activities,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/activities/:id',
    name: 'Tạo hoạt động',
    component: ActivitiesDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/registrations',
    name: 'Danh sách đăng ký',
    component: Registration,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/tour-registrations',
    name: 'Danh sách đăng ký tour',
    component: TourRegistration,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/hanoi-register',
    name: 'Danh sách đăng ký Genio Hà Nội',
    component: RegisterHaNoi,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/registrations/:id',
    name: 'Tạo đăng ký',
    component: RegistrationDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/news',
    name: 'Danh sách tin tức',
    component: News,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/news/:id',
    name: 'Tạo tin tức',
    component: NewsDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/promotions',
    name: 'Danh sách ưu đãi',
    component: Promotions,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/promotions/:id',
    name: 'Tạo ưu đãi',
    component: PromotionDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/coachs',
    name: 'Danh sách HLV',
    component: Coach,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/coachs/:id',
    name: 'Tạo HLV',
    component: CoachDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: IntroductionManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/introduction/:id',
    name: 'Introduction',
    component: IntroductionDetailManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/news-management',
    name: 'News',
    component: NewManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/login',
    key: 'Login',
    name: 'Login',
    component: Login,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
  {
    path: '/register',
    key: 'Register',
    name: 'Register',
    component: Register,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
  {
    path: '/404',
    key: 'Page404',
    name: 'Page404',
    component: Page404,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
  {
    path: '/500',
    key: 'Page500',
    name: 'Page500',
    component: Page500,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
  {
    path: '/branch-category',
    name: 'Danh sách danh mục cơ sở',
    component: BranchCategory,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/branch-category/:id',
    name: 'Tạo đanh mục cơ sở',
    component: BranchCategoryDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/branch',
    name: 'Danh sách cơ sở',
    component: Branch,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/branch/:id',
    name: 'Tạo cơ sở',
    component: BranchDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
];

export const routes = allRoutes.reduce((obj, item) => {
  const temp = { ...obj };
  if (item.key) temp[item.key.toLowerCase()] = item;
  return temp;
}, {});

export default allRoutes;
