/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CDataTable,
  CBadge,
  CPagination,
  CFormGroup,
  CLabel,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CButton,
} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import { freeSet } from '@coreui/icons';
import { useDispatch, useSelector } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { fetchTourRegistration } from 'src/slices/TourSlice';
import { setItem } from 'src/slices/TourRegistrationItemSlice';

const TourRegistration = () => {
  const fields = [
    { key: 'id', label: '#' },
    { key: 'name', label: 'Họ tên' },
    { key: 'phone', label: 'Phone' },
    { key: 'quantity', label: 'Số lượng' },
    { key: 'is_learning', label: 'Đang học tại GENIO' },
  ];
  const tourRegistration = useSelector(state => state.tour);
  const tourRegistrationItem = useSelector(state => state.tourItem);

  const dispatch = useDispatch();

  const getBadge = status => {
    switch (status) {
      case 'active':
        return 'success';
      case 'inactive':
        return 'danger';
      default:
        return 'primary';
    }
  };

  const { entities, loading, currentPage, totalPage, total } = tourRegistration;
  const { status, search } = tourRegistrationItem;

  useEffect(() => {
    dispatch(fetchTourRegistration({ page: currentPage, limit: 10 }));
  }, []);

  const handleSearch = () => {
    dispatch(fetchTourRegistration({ search, status, page: 1, limit: 10 }));
  };

  const setFilter = e => {
    const { name, value } = e.target;
    dispatch(setItem({ name, value }));
  };

  return (
    <>
      <CCard>
        <CCardHeader>Danh sách đăng ký</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol class="w-50">
              <CFormGroup>
                <CLabel htmlFor="search">Tìm kiếm</CLabel>
                <CInputGroup>
                  <CInput
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Nhập thông tin muốn tìm kiếm"
                    autoComplete="off"
                    onChange={setFilter}
                  />
                  <CInputGroupAppend>
                    <CInputGroupText className="bg-info text-white">
                      <CIcon content={freeSet.cilSearch} />
                    </CInputGroupText>
                  </CInputGroupAppend>
                </CInputGroup>
              </CFormGroup>
            </CCol>
            {/* <CCol>
              <CFormGroup>
                <CLabel htmlFor="status">Trạng thái</CLabel>
                <CSelect custom name="status" id="status" value={null} onChange={setFilter}>
                  <option value="">Tất cả</option>
                  {Object.keys(Status).map(key => (
                    <option value={Status[key].value}>{Status[key].name}</option>
                  ))}
                </CSelect>
              </CFormGroup>
            </CCol> */}
          </CRow>
          <CRow>
            <CCol>
              <CButton
                className="mb-2 float-right"
                color="info"
                onClick={() => {
                  handleSearch();
                }}
              >
                Search
              </CButton>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CCard>
                <CCardBody>
                  <CDataTable
                    items={entities}
                    fields={fields}
                    hover
                    striped
                    bordered
                    size="sm"
                    loading={loading === 'pending'}
                    itemsPerPage={10}
                    activePage={1}
                    scopedSlots={{
                      image: item => (
                        <td>
                          <img src={item.image} alt="" width="80" />
                        </td>
                      ),
                      status: item => (
                        <td>
                          <CBadge color={getBadge(item.status)} role="button">
                            {item.status}
                          </CBadge>
                        </td>
                      ),
                      is_learning: item => <td>{item.is_learning === 1 ? 'Có' : 'Không'}</td>,
                    }}
                  />
                  {total > 0 && (
                    <CRow>
                      <CCol>
                        <b>Total: {total}</b>
                      </CCol>
                      <CCol className="text-right">
                        <CPagination
                          activePage={currentPage}
                          pages={totalPage}
                          onActivePageChange={page =>
                            dispatch(fetchTourRegistration({ page, limit: 10 }))
                          }
                          align="end"
                        />
                      </CCol>
                    </CRow>
                  )}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};

export default TourRegistration;
