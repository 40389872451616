/* eslint-disable radix */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  CRow,
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CInputCheckbox,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { deleteClasses, fetchClasses, insertUpdateClasses } from 'src/slices/ClassSlice';
import Collapse from 'src/views/pages/classes/extensions/collapseComponent';

const Classes = () => {
  const dispatch = useDispatch();
  const classes = useSelector(state => state.class);
  const [isReload, setIsReload] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    dispatch(fetchClasses());
  }, [isReload]);

  const toggle = () => {
    setModal(!modal);
  };

  const formik = useFormik({
    initialValues: {
      classname_vi: '',
      classname_en: '',
      classname_jp: '',
      status: 'inactive',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      classname_vi: Yup.string().required('Vui lòng điền tên lớp Tiếng Việt'),
      classname_en: Yup.string().required('Vui lòng điền tên lớp Tiếng Anh'),
      classname_jp: Yup.string().required('Vui lòng điền tên lớp Tiếng Nhật'),
    }),
    onSubmit: async vals => {
      const res = await dispatch(insertUpdateClasses({ data: { ...vals } }));
      toggle();
      if (res.payload === true) {
        setIsReload(!isReload);
      }
    },
  });

  const onHandleDeleteCollapse = async classId => {
    const res = await dispatch(deleteClasses({ id: classId }));
    if (res.payload === true) {
      setIsReload(!isReload);
    }
  };

  const {
    handleReset,
    handleSubmit,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
  } = formik;

  const setDataModal = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
  };

  return (
    <>
      <div>
        <CButton onClick={toggle} color="primary" className="mb-3">
          Thêm lớp
        </CButton>
        {classes.entities.map((k, index) => (
          <Collapse key={index} data={k} onClickRemove={onHandleDeleteCollapse} />
        ))}
      </div>

      {!modal ? (
        ''
      ) : (
        <CForm
          action=""
          method="post"
          encType="multipart/form-data"
          className="form-horizontal"
          onSubmit={handleSubmit}
        >
          <CModal show={modal} closeOnBackdrop={false}>
            <CModalHeader closeButton={false}>Tạo lớp</CModalHeader>
            <CModalBody>
              <CRow>
                <CCol xs="12">
                  <CFormGroup>
                    <CLabel htmlFor="classname_vi">Tên lớp Tiếng Việt</CLabel>
                    <CInput
                      id="classname_vi"
                      name="classname_vi"
                      onChange={e => setDataModal(e.target.name, e.target.value)}
                      value={values.classname_vi || ''}
                    />
                    {touched.classname_vi && errors.classname_vi ? (
                      <small className="text-danger">{errors.classname_vi}</small>
                    ) : null}
                  </CFormGroup>
                </CCol>
                <CCol xs="12">
                  <CFormGroup>
                    <CLabel htmlFor="classname_en">Tên lớp Tiếng Anh</CLabel>
                    <CInput
                      id="classname_en"
                      name="classname_en"
                      onChange={e => setDataModal(e.target.name, e.target.value)}
                      value={values.classname_en || ''}
                    />
                    {touched.classname_en && errors.classname_en ? (
                      <small className="text-danger">{errors.classname_en}</small>
                    ) : null}
                  </CFormGroup>
                </CCol>
                <CCol xs="12">
                  <CFormGroup>
                    <CLabel htmlFor="classname_jp">Tên lớp Tiếng Nhật</CLabel>
                    <CInput
                      id="classname_jp"
                      name="classname_jp"
                      onChange={e => setDataModal(e.target.name, e.target.value)}
                      value={values.classname_jp || ''}
                    />
                    {touched.classname_jp && errors.classname_jp ? (
                      <small className="text-danger">{errors.classname_jp}</small>
                    ) : null}
                  </CFormGroup>
                </CCol>
                <CCol xs="6">
                  <CFormGroup variant="custom-checkbox" inline>
                    <CInputCheckbox
                      id="status_class"
                      name="status_class"
                      checked={values.status === 'active' ? 1 : 0}
                      onChange={e =>
                        setDataModal('status', values.status === 'active' ? 'inactive' : 'active')
                      }
                    />
                    <CLabel htmlFor="status_class">Kích hoạt</CLabel>
                  </CFormGroup>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <CButton color="primary" onClick={handleSubmit}>
                Lưu
              </CButton>
              <CButton
                color="secondary"
                onClick={e => {
                  toggle();
                  handleClean(e);
                  handleReset();
                }}
              >
                Cancel
              </CButton>
            </CModalFooter>
          </CModal>
        </CForm>
      )}
    </>
  );
};

export default Classes;
