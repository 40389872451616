import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './slices/AuthSlice';
import SidebarReducer from './slices/SidebarSlice';
import JobReducer from './slices/JobSlice';
import CategoryReducer from './slices/JobCatergoriesSlice';
import MessageReducer from './slices/MessageSlice';
import JobItemReducer from './slices/JobItemSlice';
import cvReducer from './slices/CVSlice';
import contactReducer from './slices/ContactSlice';
import blockReducer from './slices/BlockSlice';
import bodReducer from './slices/BodSlice';
import bodItemReducer from './slices/BodItemSlice';
import newReducer from './slices/NewSlice';
import newItemReducer from './slices/NewItemSlice';
import serviceReducer from './slices/ServiceSlice';
import serviceItemReducer from './slices/ServiceItemSlice';
import serviceCategoryReducer from './slices/ServiceCategorySlice';
import potentialCandidateReducer from './slices/PotentialCandidateSlice';
import configReducer from './slices/ConfigSlice';
import introductionItemReducer from './slices/IntroductionItemSlice';
import introductionReducer from './slices/IntroductionSlice';
import ReviewSlice from './slices/ReviewSlice';
import ReviewItemSlice from './slices/ReviewItemSlice';
import ActivitySlice from './slices/ActivitySlice';
import ActivityItemSlice from './slices/ActivityItemSlice';
import RegistrationSlice from './slices/RegistrationSlice';
import RegistrationItemSlice from './slices/RegistrationItemSlice';
import NewsSlice from './slices/NewsSlice';
import NewsItemSlice from './slices/NewsItemSlice';
import CourseSlice from './slices/CourseSlice';
import CourseItemSlice from './slices/CourseItemSlice';
import TuitionSlice from './slices/TuitionSlice';
import TuitionItemSlice from './slices/TuitionItemSlice';
import PromotionSlice from './slices/PromotionSlice';
import PromotionItemSlice from './slices/PromotionItemSlice';
import CoachSlice from './slices/CoachSlice';
import CoachItemSlice from './slices/CoachItemSlice';
import TourSlice from './slices/TourSlice';
import HanoiSlice from './slices/HanoiSlice';
import TourRegistrationItemSlice from './slices/TourRegistrationItemSlice';
import HaNoiRegisterItemSlice from './slices/HaNoiRegisterItemSlice';
import BranchCategoryItem from './slices/BranchCategoryItemSlice';
import BranchCategory from './slices/BranchCategorySlice';
import Branch from './slices/BranchSlice';
import BranchItem from './slices/BranchItemSlice';
import Group from './slices/GroupSlice';
import GroupItem from './slices/GroupItemSlice';
import Class from './slices/ClassSlice';

export default configureStore({
  reducer: {
    auth: AuthReducer,
    sidebar: SidebarReducer,
    job: JobReducer,
    category: CategoryReducer,
    message: MessageReducer,
    jobItem: JobItemReducer,
    cv: cvReducer,
    contact: contactReducer,
    block: blockReducer,
    bod: bodReducer,
    bodItem: bodItemReducer,
    new: newReducer,
    newItem: newItemReducer,
    service: serviceReducer,
    serviceItem: serviceItemReducer,
    review: ReviewSlice,
    reviewItem: ReviewItemSlice,
    activity: ActivitySlice,
    activityItem: ActivityItemSlice,
    registration: RegistrationSlice,
    registrationItem: RegistrationItemSlice,
    news: NewsSlice,
    newsItem: NewsItemSlice,
    serviceCategory: serviceCategoryReducer,
    potentialCandidate: potentialCandidateReducer,
    config: configReducer,
    introductionItem: introductionItemReducer,
    introduction: introductionReducer,
    course: CourseSlice,
    courseItem: CourseItemSlice,
    tuition: TuitionSlice,
    tuitionItem: TuitionItemSlice,
    promotion: PromotionSlice,
    promotionItem: PromotionItemSlice,
    coach: CoachSlice,
    coachItem: CoachItemSlice,
    tour: TourSlice,
    tourItem: TourRegistrationItemSlice,
    hanoi: HanoiSlice,
    hanoiItem: HaNoiRegisterItemSlice,
    branchCategoryItem: BranchCategoryItem,
    branchCategory: BranchCategory,
    branch: Branch,
    branchItem: BranchItem,
    group: Group,
    groupItem: GroupItem,
    class: Class,
  },
});
