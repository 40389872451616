import { CButton, CCol, CForm, CFormGroup, CInput, CLabel, CRow } from '@coreui/react';
import { useFormik } from 'formik';
import React, { forwardRef, useImperativeHandle } from 'react';
import * as Yup from 'yup';

function ChildForm({ defaultData, onRemove, hasRemove = false }, ref) {
  const formik = useFormik({
    initialValues: {
      id: defaultData.id || '',
      child_name: defaultData.child_name || '',
      child_birthday: defaultData.child_birthday || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      child_name: Yup.string().required('Vui lòng điền tên của bé'),
      child_birthday: Yup.number(),
    }),
    onSubmit: async vals => {
      //   const res = await dispatch(
      //     upsertRegistration({ id: id === 'create' ? '' : id, data: { ...vals } }),
      //   );
      //   if (res.payload === true) {
      //     history.push(`/registrations`);
      //   }
    },
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
  } = formik;

  const setData = (name, value) => {
    setFieldValue(name, value);
  };
  useImperativeHandle(ref, () => ({
    formik,
  }));
  return (
    <CForm
      action=""
      className="form-horizontal mt-2"
      style={{ border: '1px solid gray', borderRadius: '10px', padding: '20px' }}
      onSubmit={handleSubmit}
    >
      <CRow>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="child_name">Họ tên bé</CLabel>
            <CInput
              id="child_name"
              name="child_name"
              onChange={e => setData(e.target.name, e.target.value)}
              value={values.child_name || ''}
            />
            {touched.child_name && errors.child_name ? (
              <small className="text-danger">{errors.child_name}</small>
            ) : null}
          </CFormGroup>
        </CCol>
        <CCol xs="6">
          <CFormGroup>
            <CLabel htmlFor="child_birthday">Năm sinh</CLabel>
            <CInput
              type="number"
              id="child_birthday"
              name="child_birthday"
              onChange={e => setData(e.target.name, e.target.value)}
              value={values.child_birthday || ''}
            />
            {touched.child_birthday && errors.child_birthday ? (
              <small className="text-danger">{errors.child_birthday}</small>
            ) : null}
          </CFormGroup>
        </CCol>
        {hasRemove && (
          <CCol xs="6">
            <CButton color="danger" style={{ marginRight: 10 }} type="button" onClick={onRemove}>
              Xóa Bé
            </CButton>
          </CCol>
        )}
      </CRow>
    </CForm>
  );
}

export default forwardRef(ChildForm);
