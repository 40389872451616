/* eslint-disable react/prefer-stateless-function */
import React, { useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { CToaster, CToast, CToastHeader, CToastBody } from '@coreui/react';
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import PrivateRoute from './components/PrivateRoute';
import allRoutes from './routes';
import { CookieService } from './libs/cookie.service';
import constants from './constants';
import { logout, handleLogin } from './slices/AuthSlice';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

const App = () => {
  const { show, color, timeOut, header, body } = useSelector(state => state.message);

  const dispatch = useDispatch();
  useEffect(() => {
    const token = CookieService.get(constants.USER_ACCESS_TOKEN);

    if (!token) return;
    const decoded = jwt_decode(token);
    const isExpired = Date.now() >= decoded.exp * 1000;

    if (isExpired) dispatch(logout());
    else {
      dispatch(handleLogin({ token }));
    }
  }, []);

  return (
    <>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            {allRoutes
              .filter(item => !item.hasLayout)
              .map((route, idx) => {
                return (
                  route.component &&
                  (route.isPrivate ? (
                    <PrivateRoute
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} />}
                    />
                  ) : (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} />}
                    />
                  ))
                );
              })}
            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
      <CToaster position="top-right">
        <CToast key={new Date().getTime()} show={show} autohide={timeOut} fade color={color}>
          {header && <CToastHeader closeButton>{header}</CToastHeader>}
          {body && <CToastBody className="font-weight-bold text-capitalize">{body}</CToastBody>}
        </CToast>
      </CToaster>
    </>
  );
};

export default App;
