/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CInputCheckbox,
  CButton,
  CForm,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { detailCategory, upsertCategory } from 'src/slices/BranchCategorySlice';
import { resetData } from 'src/slices/BranchCategoryItemSlice';

const BranchCategoryDetail = () => {
  const routeParams = useParams();
  const { id } = routeParams;
  const item = useSelector(state => state.branchCategoryItem);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      name: item.name || '',
      description: item.description || '',
      order: item.order || '',
      status: item.status || 'inactive',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Vui lòng điền tên danh mục'),
    }),
    onSubmit: async vals => {
      const res = await dispatch(
        upsertCategory({ id: id === 'create' ? '' : id, data: { ...vals } }),
      );
      if (res.payload === true) {
        history.push(`/branch-category`);
      }
    },
  });

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    history.push(`/branch-category`);
  };

  useEffect(() => {
    (async () => {
      if (id !== 'create') {
        await dispatch(detailCategory(id));
      } else {
        dispatch(resetData());
      }
    })();
  }, [id]);
  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
        validated={validated}
      >
        <CCard>
          <CCardHeader>Tạo hoạt động</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="category_name">Tên danh mục</CLabel>
                  <CInput
                    id="category_name"
                    name="name"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.name || ''}
                  />
                  {touched.name && errors.name ? (
                    <small className="text-danger">{errors.name}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="description">Miêu tả</CLabel>
                  <CInput
                    id="description"
                    name="description"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.description || ''}
                  />
                  {touched.description && errors.description ? (
                    <small className="text-danger">{errors.description}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="order">Thứ tự</CLabel>
                  <CInput
                    type="number"
                    id="order"
                    name="order"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.order || ''}
                  />
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="4">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="status"
                    name="status"
                    checked={values.status === 'active' ? 1 : 0}
                    onChange={e =>
                      setData(e.target.name, values.status === 'active' ? 'inactive' : 'active')
                    }
                  />
                  <CLabel htmlFor="status">Kích hoạt</CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter align="center">
            <CButton
              color="primary"
              style={{ marginRight: 10 }}
              type="submit"
              disabled={isSubmitting}
            >
              Lưu
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              Không
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style global jsx>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default BranchCategoryDetail;
