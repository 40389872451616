/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CFormGroup,
  CLabel,
  CInput,
  CInputCheckbox,
  CButton,
  CForm,
  CSelect,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { fetchGroup } from 'src/slices/GroupSlice';
import { detailCourse, upsertCourse } from 'src/slices/CourseSlice';
import { resetData } from 'src/slices/CourseItemSlice';

const CourseDetail = () => {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = routeParams;
  const [validated, setValidated] = useState(false);

  const courseItem = useSelector(state => state.courseItem);
  const group = useSelector(state => state.group);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    values, // use this if you want controlled components
    errors,
    initialValues,
  } = useFormik({
    initialValues: {
      group_id: courseItem.group_id || '',
      course_month_vi: courseItem.course_month_vi || '',
      course_month_en: courseItem.course_month_en || '',
      course_month_jp: courseItem.course_month_jp || '',
      course_time_vi: courseItem.course_time_vi || '',
      course_time_en: courseItem.course_time_en || '',
      course_time_jp: courseItem.course_time_jp || '',
      status: courseItem.status || 'inactive',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      group_id: Yup.string().required('Vui lòng chọn loại nhóm'),
      course_month_vi: Yup.string().required('Vui lòng điền thời lượng tiếng Việt'),
      course_month_en: Yup.string().required('Vui lòng điền thời lượng tiếng Anh'),
      course_month_jp: Yup.string().required('Vui lòng điền thời lượng tiếng Nhật'),
      course_time_vi: Yup.string().required('Vui lòng điền thời lượng học bù tiếng Việt'),
      course_time_en: Yup.string().required('Vui lòng điền thời lượng học bù tiếng Anh'),
      course_time_jp: Yup.string().required('Vui lòng điền thời lượng học bù tiếng Nhật'),
    }),
    onSubmit: async vals => {
      const res = await dispatch(
        upsertCourse({ id: id === 'create' ? '' : id, data: { ...vals } }),
      );

      if (res.payload === true) {
        history.push(`/courses`);
      }
    },
  });

  const setData = (name, value) => {
    setFieldValue(name, value);
  };

  const handleClean = e => {
    e.preventDefault();
    dispatch(resetData());
    history.push(`/courses`);
  };

  useEffect(() => {
    const load = async () => {
      if (id !== 'create') {
        await dispatch(detailCourse(id));
      } else {
        dispatch(resetData());
      }

      load();
    };
  }, [id]);

  useEffect(() => {
    dispatch(fetchGroup());
  }, []);

  return (
    <>
      <CForm
        action=""
        method="post"
        encType="multipart/form-data"
        className="form-horizontal"
        onSubmit={handleSubmit}
        validated={validated}
      >
        <CCard>
          <CCardHeader>
            {id === 'create' ? 'Tạo thời lượng khóa học' : 'Chỉnh sửa thời lượng khóa học'}
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="course_month_vi">Loại nhóm</CLabel>
                  <CSelect
                    custom
                    name="group_id"
                    id="group_id"
                    value={values.group_id || ''}
                    onChange={e => setData(e.target.name, e.target.value)}
                  >
                    <option disabled value="" />
                    {group.entities.map((k, index) => (
                      <option key={index} value={k.id}>
                        {k.group_name_vi}
                      </option>
                    ))}
                  </CSelect>
                  {touched.group_id && errors.group_id ? (
                    <small className="text-danger">{errors.group_id}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="course_month_vi">Thời lượng khóa học Tiếng Việt</CLabel>
                  <CInput
                    id="course_month_vi"
                    name="course_month_vi"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.course_month_vi || ''}
                  />
                  {touched.course_month_vi && errors.course_month_vi ? (
                    <small className="text-danger">{errors.course_month_vi}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="course_month_en">Thời lượng khóa học Tiếng Anh</CLabel>
                  <CInput
                    id="course_month_en"
                    name="course_month_en"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.course_month_en || ''}
                  />
                  {touched.course_month_en && errors.course_month_en ? (
                    <small className="text-danger">{errors.course_month_en}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="course_month_jp">Thời lượng khóa học Tiếng Nhật</CLabel>
                  <CInput
                    id="course_month_jp"
                    name="course_month_jp"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.course_month_jp || ''}
                  />
                  {touched.course_month_jp && errors.course_month_jp ? (
                    <small className="text-danger">{errors.course_month_jp}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="course_time_vi">Thời lượng học bù Tiếng Việt</CLabel>
                  <CInput
                    id="course_time_vi"
                    name="course_time_vi"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.course_time_vi || ''}
                  />
                  {touched.course_time_vi && errors.course_time_vi ? (
                    <small className="text-danger">{errors.course_time_vi}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="course_time_en">Thời lượng học bù Tiếng Anh</CLabel>
                  <CInput
                    id="course_time_en"
                    name="course_time_en"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.course_time_en || ''}
                  />
                  {touched.course_time_en && errors.course_time_en ? (
                    <small className="text-danger">{errors.course_time_en}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
              <CCol xs="12">
                <CFormGroup>
                  <CLabel htmlFor="course_time_jp">Thời lượng học bù Tiếng Nhật</CLabel>
                  <CInput
                    id="course_time_jp"
                    name="course_time_jp"
                    onChange={e => setData(e.target.name, e.target.value)}
                    value={values.course_time_jp || ''}
                  />
                  {touched.course_time_jp && errors.course_time_jp ? (
                    <small className="text-danger">{errors.course_time_jp}</small>
                  ) : null}
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CFormGroup variant="custom-checkbox" inline>
                  <CInputCheckbox
                    id="status"
                    name="status"
                    checked={values.status === 'active' ? 1 : 0}
                    onChange={e =>
                      setData(e.target.name, values.status === 'active' ? 'inactive' : 'active')
                    }
                  />
                  <CLabel htmlFor="status">Kích hoạt</CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter align="center">
            <CButton color="primary" style={{ marginRight: 10 }} type="submit">
              Lưu
            </CButton>
            <CButton color="secondary" onClick={handleClean}>
              Không
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
      <style global jsx>
        {`
          .horizontal-slider {
            width: 100%;
            max-width: 700px;
            height: 10vh;
            margin: auto;
          }
          .horizontal-text {
            max-width: 700px;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
          }
          .horizontal-text div {
            text-align: center;
          }
          .horizontal-text p {
            background-color: #fca432;
            padding: 7px;
            color: white;
            border-radius: 15px;
          }
          .example-thumb {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            background: #ffffff;
            border: 5px solid #fca432;
            border-radius: 100%;
            display: block;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
          }
          .example-thumb.active {
            background-color: grey;
          }
          .example-track {
            position: relative;
            background: #ddd;
          }
          .example-track.example-track-0 {
            background: #fca432;
          }
          .horizontal-slider .example-track {
            top: 15px;
            height: 5px;
          }
          .horizontal-slider .example-thumb {
            top: 12px;
            width: 10px;
            outline: none;
            height: 10px;
            line-height: 38px;
          }
        `}
      </style>
    </>
  );
};

export default CourseDetail;
